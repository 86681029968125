<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ highlightId ? 'Edit' : 'New' }} highlight <strong>Memoir {{ item.profile ? item.profile.year : (activeMemoir ? activeMemoir.year : '') }}</strong><br>
                <div
                  v-if="item.last_modification"
                  class="mt-1"
                >
                  <small>Last modification:
                    <strong>{{ item.last_modification }}</strong></small>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back "
              @click="$router.push({name: 'researcher.memoir-highlight.index'})"
            ><i data-feather="chevron-left" /></a>

            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseResearcher`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Researcher</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseResearcher`"
                visible
              >
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Search researcher</label>
                          <v-select
                            v-model="item.profile_user"
                            label="name"
                            :options="users"
                            :get-option-key="option => option.id"
                            :placeholder="'Type 3 or more characters to search...'"
                            @search="onSearchResearcher({ name: $event }, 'users/fetchFakeUsers')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!-- ACCESS -->
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseAccess`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a
                    data-action="collapse"
                  >Access
                    <span
                      class="text-warning"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Special access active / Memoir proof access active"
                    ><i data-feather="alert-triangle" /></span></a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a
                        data-action="collapse"
                      ><i
                        data-feather="chevron-down"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseAccess`"
                  visible
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['access.open_access'] }}</label>
                          <div class="form-check mb-1">
                            <input
                              id="openaccess"
                              class="form-check-input"
                              type="checkbox"
                              value="checked"
                              checked
                              disabled
                            >
                            <label
                              class="form-check-label"
                              for="openaccess"
                            >Yes</label>
                          </div>
                          <date-picker
                            v-model="activeMemoir.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['access.special_access'] }}</label>
                          <div class="form-check mb-1">
                            <input
                              id="specialaccess"
                              v-model="item.special_access"
                              class="form-check-input"
                              type="checkbox"
                              @change="
                                !$event.target.checked
                                  ? (item.special_access_deadline = null)
                                  : ''
                              "
                            >
                            <label
                              class="form-check-label"
                              for="specialaccess"
                            >Yes</label>
                          </div>
                          <date-picker
                            v-model="item.special_access_deadline"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            :disabled="!item.special_access"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <template v-for="number in [2]">
              <div
                v-if="activeMemoir[`knowledge_base_link_${number}_content`]"
                :key="number"
                class="card"
              >
                <div
                  v-b-toggle="`collapseInstructions${number}`"
                  class="card-header"
                >
                  <h3 class="cart-title">
                    <a data-action="collapse">Instructions</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="card-content collapse show"
                >
                  <b-collapse
                    :id="`collapseInstructions${number}`"
                  >
                    <div class="card-body row">
                      <div
                        class="col-12"
                        v-html="activeMemoir[`knowledge_base_link_${number}_content`]"
                      />
                    </div>
                  </b-collapse>
                </div>
              </div>
            </template>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a
                        data-action="collapse"
                      ><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div
                      v-if="loggedUser.roles.includes('super-admin')"
                      class="row"
                    >
                      <div class="col-sm-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.status'] }}</label>
                          <v-select
                            v-model="item.status"
                            label="status"
                            :options="statuses"
                            :get-option-key="option => option.id"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-1 required">
                        <label
                          for=""
                          class="form-label disp-bl"
                        >{{ labels['content.title'] }}</label>
                        <div id="full-wrapper">
                          <div id="full-container">
                            <quill-editor
                              v-if="canEdit"
                              v-model="item.title"
                            />
                            <div
                              v-else
                              class="editor"
                              style="height:300px"
                              v-html="item.title"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-1 required">
                        <label
                          for=""
                          class="form-label disp-bl"
                        >{{ labels['content.text'] }} <span
                          v-if="canEdit"
                          class="text-danger float-end"
                        >{{ textLength }}/2200</span></label><!-- editor -->
                        <div id="full-wrapper">
                          <div id="full-container">
                            <quill-editor
                              v-if="canEdit"
                              ref="quillText"
                              v-model="item.text"
                            />
                            <div
                              v-else
                              class="editor"
                              style="height:300px"
                              v-html="item.text"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-1 required">
                        <label
                          for=""
                          class="form-label disp-bl"
                        >{{ labels['content.references'] }}</label><!-- editor -->
                        <div id="full-wrapper">
                          <div id="full-container">
                            <quill-editor
                              v-if="canEdit"
                              ref="quillReference"
                              v-model="item.references"
                            />
                            <div
                              v-else
                              class="editor"
                              style="height:300px"
                              v-html="item.references"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CO-AUTORS -->
            <div
              class="card"
            >
              <div
                v-b-toggle="`collapseCoAuthors`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a
                    data-action="collapse"
                  >ICREA Coauthors
                    <span
                      class="text-warning"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Coauthors"
                    /></a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a
                        data-action="collapse"
                      ><i
                        data-feather="chevron-down"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <b-collapse
                  :id="`collapseCoAuthors`"
                  visible
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['icrea_coauthors.select_icreas'] }}</label>
                          <v-select
                            v-model="item.coAuthors"
                            multiple
                            label="name"
                            :options="users"
                            :get-option-key="option => option.id"
                            :disabled="!canEdit"
                            @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>

            <!-- IMAGES -->
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Images</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a
                        data-action="collapse"
                      ><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <h5>Image 1</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div
                          v-if="blob_url_1 || icon_path_1"
                          class="col-sm-4"
                        >
                          <img
                            v-if="blob_url_1"
                            height="100%"
                            width="100%"
                            :src="blob_url_1"
                          >
                          <img
                            v-if="icon_path_1"
                            height="100%"
                            width="100%"
                            :src="icon_path_1"
                          >
                        </div>
                        <div
                          v-if="canEdit"
                          class="col-sm-8"
                        >
                          <div class="mb-1">
                            <label
                              class="form-label"
                              for=""
                            >{{ labels['images.select_image'] }}</label>
                            <input
                              id="formFileImage"
                              :ref="`file-highlight-image-1`"
                              class="form-control"
                              type="file"
                              @change="uploadFile('file-highlight-image-1')"
                            >
                          </div>
                          <small class="mt-1">
                            {{ labels['images.file_size'] }} <strong>2 MB</strong>.<br>
                            {{ labels['images.allowed_file_types'] }}
                            <strong>png, gif, webp or jpg</strong>.
                          </small>
                          <p class="mt-1">
                            <a
                              class="btn bg-light-danger btn-sm"
                              @click="deleteImage('1')"
                            ><i data-feather="trash-2" /> Delete photo</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['images.caption'] }}</label>
                        <textarea
                          id=""
                          v-model="item.image_1_caption"
                          rows="4"
                          class="form-control"
                          :disabled="!canEdit"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Image 2</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div
                          v-if="blob_url_2 || icon_path_2"
                          class="col-sm-4"
                        >
                          <img
                            v-if="blob_url_2"
                            height="100%"
                            width="100%"
                            :src="blob_url_2"
                          >
                          <img
                            v-if="icon_path_2"
                            height="100%"
                            width="100%"
                            :src="icon_path_2"
                          >
                        </div>
                        <div
                          v-if="canEdit"
                          class="col-sm-8"
                        >
                          <div class="mb-1">
                            <label
                              class="form-label"
                              for=""
                            >{{ labels['images.select_image'] }}</label>
                            <input
                              id="formFileImage"
                              :ref="`file-highlight-image-2`"
                              class="form-control"
                              type="file"
                              @change="uploadFile('file-highlight-image-2')"
                            >
                          </div>
                          <small class="mt-1">
                            {{ labels['images.file_size'] }} <strong>2 MB</strong>.<br>
                            {{ labels['images.allowed_file_types'] }}
                            <strong>png, gif, webp or jpg</strong>.
                          </small>
                          <p class="mt-1">
                            <a
                              class="btn bg-light-danger btn-sm"
                              @click="deleteImage('2')"
                            ><i data-feather="trash-2" /> Delete photo</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['images.caption'] }}</label>
                        <textarea
                          id=""
                          v-model="item.image_2_caption"
                          rows="4"
                          class="form-control"
                          :disabled="!canEdit"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Image 3</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div
                          v-if="blob_url_3 || icon_path_3"
                          class="col-sm-4"
                        >
                          <img
                            v-if="blob_url_3"
                            height="100%"
                            width="100%"
                            :src="blob_url_3"
                          >
                          <img
                            v-if="icon_path_3"
                            height="100%"
                            width="100%"
                            :src="icon_path_3"
                          >
                        </div>
                        <div
                          v-if="canEdit"
                          class="col-sm-8"
                        >
                          <div class="mb-1">
                            <label
                              class="form-label"
                              for=""
                            >{{ labels['images.select_image'] }}</label>
                            <input
                              id="formFileImage"
                              :ref="`file-highlight-image-3`"
                              class="form-control"
                              type="file"
                              @change="uploadFile('file-highlight-image-3')"
                            >
                          </div>
                          <small class="mt-1">
                            {{ labels['images.file_size'] }} <strong>2 MB</strong>.<br>
                            {{ labels['images.allowed_file_types'] }}
                            <strong>png, gif, webp or jpg</strong>.
                          </small>
                          <p class="mt-1">
                            <a
                              class="btn bg-light-danger btn-sm"
                              @click="deleteImage('3')"
                            ><i data-feather="trash-2" /> Delete photo</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['images.caption'] }}</label>
                        <textarea
                          id=""
                          v-model="item.image_3_caption"
                          rows="4"
                          class="form-control"
                          :disabled="!canEdit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <InternalNotes
              :notes="item.notes"
              @saveNewNote="saveNewNote"
            />
          </div>
        </div>

        <div
          class="page-actions d-flex align-items-center justify-content-between"
        >
          <div
            v-if="canEdit || loggedUser.roles.includes('super-admin')"
          >
            <button
              class="btn btn-info"
              @click="save(false)"
            >
              <i data-feather="save" /> Save draft
            </button>
            <button
              v-if="!sending"
              id="type-success"
              class="btn btn-success mx-1"
              @click="save(true)"
            >
              <i data-feather="save" /> Save and submit
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving...</span>
            </button>
          </div>
          <!-- <div>
            <button class="btn btn-outline-danger">
              <i data-feather="save"></i> Cancel
            </button>
            <button class="btn btn-danger">
              <i data-feather="save"></i> Delete
            </button>
          </div> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    vSelect,
    DatePicker,
    BCollapse,
    InternalNotes,
  },
  data() {
    return {
      highlightId: this.$route.params.id,
      sending: false,
      errors: '',
      icon_path_1: '',
      blob_url_1: '',
      icon_path_2: '',
      blob_url_2: '',
      icon_path_3: '',
      blob_url_3: '',
      textLength: 0,
      loading: true,
      canEdit: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirHighlights/item',
      statuses: 'status/statuses',
      loggedUser: 'auth/admin',
      users: 'users/fakeUsers',
      activeMemoir: 'memoirs/activeMemoir',
      labels: 'sectionLabels/itemLabels',
    }),
    checkTextCounter() {
      return this.item.text
    },
    checkReferencesCounter() {
      return this.item.references
    },
  },
  watch: {
    checkTextCounter() {
      if (this.$refs.quillText.quill.getLength() - 1 > 2200) {
        this.$refs.quillText.quill.deleteText(
          2200,
          this.$refs.quillText.quill.getLength() - 1,
        )
      }
      this.textLength = this.$refs.quillText.quill.getLength() - 1
    },
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'memoirs.memoir_highlights')
    await this.$store.dispatch('memoirs/getActiveMemoir')
    this.loading = true
    if (this.highlightId) {
      await this.$store.dispatch('memoirHighlights/fetchId', this.highlightId)
      this.canEdit = (this.item.canEdit && (this.item.profile.memoir.open || this.item.open)) || this.loggedUser.roles.includes('super-admin')
      this.textLength = this.$refs.quillText.quill.getLength() - 1
      this.icon_path_1 = this.item.image_1 ? this.item.image_1.url : null
      this.icon_path_2 = this.item.image_2 ? this.item.image_2.url : null
      this.icon_path_3 = this.item.image_3 ? this.item.image_3.url : null
    } else {
      await this.$store.dispatch('memoirHighlights/cleanType')
    }

    if (this.loggedUser.roles.includes('super-admin')) {
      this.$store.dispatch('status/filterStatus', 'Memoirs profiles')
    }
    this.loading = false
  },
  methods: {
    async uploadFile(name) {
      const file = this.$refs[name].files[0]
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
      }
      switch (name) {
        case 'file-highlight-image-1':
          this.item.image_1 = fileData
          this.icon_path_1 = ''
          this.blob_url_1 = URL.createObjectURL(file)
          break
        case 'file-highlight-image-2':
          this.item.image_2 = fileData
          this.icon_path_2 = ''
          this.blob_url_2 = URL.createObjectURL(file)
          break
        case 'file-highlight-image-3':
          this.item.image_3 = fileData
          this.icon_path_3 = ''
          this.blob_url_3 = URL.createObjectURL(file)
          break
        default:
          break
      }
    },
    async save(submit) {
      this.sending = true
      try {
        if (this.highlightId) {
          this.item.submit = submit
          await this.$store.dispatch('memoirHighlights/update', {
            id: this.highlightId,
            data: this.item,
          })
        } else {
          await this.$store.dispatch('memoirHighlights/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors == '' && this.item.id) {
        Vue.swal(
          'The memoir highlight has been saved correctly!',
          '',
          'success',
        ).then(() => {
          this.$router.push({ name: 'researcher.memoir-highlight.index' })
        })
      } else {
        // Vue.swal
      }
      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      search.role = 'researcher'
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async onSearchResearcher(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsersAux', {
          name: search.name,
          withMemoirProfile: true,
        })
      }
    },
    async deleteImage(num) {
      if (this.item) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights/destroyImage/${this.item.id}`, { image_num: num })
      }
      this.item[`image_${num}`] = ''
      this[`icon_path_${num}`] = ''
      this[`blob_url_${num}`] = ''
    },
  },
}
</script>

<style>
</style>
